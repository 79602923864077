import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import IconSair from "../assets/styles/icons8-logout-26.png";
import iconPerfil02 from "../assets/styles/iconPerfil.png";
import "../assets/styles/conta.css";

const Conta = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("userToken");
        navigate("/Login");
      })
      .catch((error) => {
        console.error("Erro ao fazer logout:", error);
      });
  };

  // Fechar o dropdown ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (user !== null) {
    user.providerData.forEach((profile) => {
      console.log("Sign-in provider: " + profile.providerId);
      console.log("  Provider-specific UID: " + profile.uid);
      console.log("  Name: " + profile.displayName);
      console.log("  Email: " + profile.email);
      console.log("  Photo URL: " + profile.photoURL);
    });
  }

  return (
    <div className="account-container" ref={dropdownRef}>
      {user && (
        <>
          <img
            src={user.photoURL || iconPerfil02}
            alt="Foto do perfil"
            className="profile-picture"
            onClick={() => setShowDropdown(!showDropdown)}
          />
          {showDropdown && (
            <div className="dropdown-menu">
              <div className="dropdown-item">Olá, {user.displayName}!</div>
              <div className="dropdown-item">
                <button onClick={handleLogout} className="logout-button">
                 <img src={IconSair}></img>  <b>Sign out</b>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Conta;
