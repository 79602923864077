import React, {useEffect} from "react";
import { useAppContext } from "./AppContext";
import "../assets/styles/style.css";

const CancelModal = ({ show, onClose, onConfirm }) => {
  const { setItems } = useAppContext();

  useEffect(() => {
    // Função para desativar a rolagem
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
    };

    // Função para ativar a rolagem
    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    if (show) {
      disableScroll();
    } else {
      enableScroll();
    }

    // Limpeza do efeito
    return () => enableScroll();
  }, [show]);

  if (!show) {
    return null;
  }

  const handleConfirm = () => {
    setItems([]);
    onConfirm();
    onConfirm();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <p>Tem certeza de que deseja cancelar o pedido?</p>
        <button className="button" onClick={handleConfirm}>
          Sim
        </button>
        <button className="button" onClick={onClose}>
          Não
        </button>
      </div>
    </div>
  );
};

export default CancelModal;
