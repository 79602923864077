import React, {useEffect } from "react";
import "../assets/styles/style.css";

const ConfirmarModal = ({ show, onClose, onAddNewItem, onCheckout }) => {
  useEffect(() => {
    // Função para desativar a rolagem
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
    };

    // Função para ativar a rolagem
    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    if (show) {
      disableScroll();
    } else {
      enableScroll();
    }

    // Limpeza do efeito
    return () => enableScroll();
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>

        <p>
          Deseja adicionar mais um item ou seguir direto para a página de
          checkout?
        </p>
        <button onClick={onCheckout}>Ir para o checkout</button>
        <button onClick={onAddNewItem}>Adicionar mais</button>
      </div>
    </div>
  );
};

export default ConfirmarModal;
