import "../assets/styles/style.css";
import Header from "./Header";
import Footer from "./Footer";
import Conta from "./Conta";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import React, { useState, useEffect, useCallback } from "react";
import ConfirmarModal from "./ConfirmarModal";
import Select from "react-select";
import ErrorField from "./ErrorField";
import { debounce } from "lodash";

export default function Form() {
  const { adicionarItemAoCarrinho } = useAppContext();
  const navigate = useNavigate();
  const [catalisador, setCatalisador] = useState("");
  const [opcoesCliente, setOpcoesCliente] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [showConfirmarModal, setShowConfirmarModal] = useState(false);
  const [observacao, setObservacao] = useState("");
  const [nomeEmbalagem, setNomeEmbalagem] = useState("");
  const [preco, setPreco] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState(() => {
    return localStorage.getItem("clienteSelecionado") || "";
  });
  const [produtoSelecionado, setProdutoSelecionado] = useState("");
  const [filtroProduto, setFiltroProduto] = useState("");
  const [cacheOpcoes, setCacheOpcoes] = useState({});
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    control,
  } = useForm();

  const handleRadioChange = async (event) => {
    setCatalisador(event.target.value);
    await trigger("catalisador");
  };

  const carregarOpcoes = useCallback(
    debounce(async (inputValue) => {
      if (cacheOpcoes[inputValue]) {
        return cacheOpcoes[inputValue];
      }
      const resposta = await fetch(`/produtos?query=${inputValue}`);
      const listaProdutos = await resposta.json();

      const opcoes = listaProdutos.map((produto) => ({
        label: produto["NOME DO PRODUTO"],
        value: produto["ID DO PRODUTO"],
      }));

      setCacheOpcoes((cache) => ({ ...cache, [inputValue]: opcoes }));
      return opcoes;
    }, 500),
    [cacheOpcoes]
  );
  const handleInputChange = debounce(carregarOpcoes, 100);

  const produtosFiltrados = produtos.filter((produto) =>
    produto["NOME DO PRODUTO"]
      .toLowerCase()
      .includes(filtroProduto.toLowerCase())
  );

  const lataCinco = 5;

  const opcoesQuantidade = [
    { label: "BALDE 18 L MET /B18", value: 18, key: "balde_18" },
    { label: "BOMB PLAST 5 LT PRETA", value: 5, key: "bomb_plast_5" },
    { label: "BOMB PLASTICA 0,225 L", value: 0.225, key: "bomb_plastica_0225" },
    { label: "BOMB PLASTICA 1 LITRO", value: 1, key: "bomb_plastica_1" },
    { label: "GALAO 3,6 LT MET TECBRIL / L01", value: 3.6, key: "galao" },
    { label: "LATA 0,9 L MET TECBRIL / L04", value: 0.9, key: "lata_09" },
    { label: "LATA 0,225 L MET S/ LITOGRAFIA", value: 0.226, key: "lata_0225" },
    { label: "LATA 1 L MET RETANGULAR / L10", value: 0.99, key: "lata_1" },
    { label: "LATA DE 5 L MET TECBRIL / L02", value: 4.99, key: "lata_5" },
    {
      label: "LATA DE 18 L HOMOL MET COM LITOGRAFIA / L51",
      value: 17.99,
      key: "lata_18",
    },
    { label: "SEM EMBALAGEM", value: 0 },
  ];

  const [opcoesPreco, setOpcoesPreco] = useState([
    { label: "2.5 vezes o Custo", value: "2.5 vezes o Custo" },
    { label: "2.4 vezes o Custo", value: "2.4 vezes o Custo" },
    { label: "2 vezes o Custo", value: "2 vezes o Custo" },
    { label: "Manter Valor", value: "Manter Valor" },
  ]);

  const adicionarOpcaoPreco = (novaOpcao) => {
    setOpcoesPreco((opcoesAnteriores) => [...opcoesAnteriores, novaOpcao]);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const message =
        "Você tem certeza de que deseja sair? Todos os dados do pedido serão perdidos.";
      e.preventDefault();
      e.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    const carregarDados = async () => {
      try {
        const cacheBuster = new Date().getTime();
        const respostaProdutos = await fetch(`/produtos.json?${cacheBuster}`);
        if (!respostaProdutos.ok) {
          throw new Error(
            `Erro na resposta do servidor: ${respostaProdutos.status}`
          );
        }
        const dadosProdutos = await respostaProdutos.json();
        setProdutos(dadosProdutos.Sheet1);

        // Carregar dados dos clientes
        const respostaClientes = await fetch("/clientes.json");
        if (!respostaClientes.ok) {
          throw new Error(
            `Erro na resposta do servidor: ${respostaClientes.status}`
          );
        }
        const dadosClientes = await respostaClientes.json();
        setOpcoesCliente(
          dadosClientes.Sheet1.map((cliente) => ({
            label: Object.values(cliente)[0],
            value: Object.values(cliente)[0],
          }))
        );
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
      const storedCliente = localStorage.getItem("clienteSelecionado");
      if (storedCliente) {
        setClienteSelecionado(storedCliente);
      }
    };

    carregarDados();
  }, []);

  useEffect(() => {
    if (clienteSelecionado) {
      setValue("cliente", clienteSelecionado, { shouldValidate: true });
    }
  }, [clienteSelecionado, setValue]);

  const encontrarEmbalagem = (quantidade) => {
    const embalagem = opcoesQuantidade.find(
      (opcao) => opcao.value === quantidade
    );
    console.log("Embalagem encontrada:", embalagem.label);
    setNomeEmbalagem(embalagem ? embalagem.label : "");
  };

  useEffect(() => {
    if (quantidadeSelecionada) {
      encontrarEmbalagem(quantidadeSelecionada);
    }
  }, [quantidadeSelecionada]);

  const onSubmit = () => {
    adicionarItemAoCarrinho(
      produtoSelecionado,
      quantidadeSelecionada,
      catalisador,
      preco,
      nomeEmbalagem
    );
    setShowConfirmarModal(true);
    localStorage.setItem("clienteSelecionado", clienteSelecionado);
    console.log("Preço", preco);
  };

  const handleAddNewItem = () => {
    setProdutoSelecionado("");
    setQuantidadeSelecionada("");
    setCatalisador("");

    setValue("produto", "");
    setValue("quantidade", "");
    setValue("catalisador", "");

    setShowConfirmarModal(false);
  };

  const handleCheckout = () => {
    console.log("Nome da embalagem no checkout:", nomeEmbalagem);
    navigate("/Checkout", {
      state: {
        produto: produtoSelecionado,
        quantidade: quantidadeSelecionada,
        catalisador,
        cliente: clienteSelecionado,
        observacao: observacao,
        embalagem: nomeEmbalagem,
      },
    });
    // Fecha o modal
    setShowConfirmarModal(false);
  };

  useEffect(() => {
    console.log(
      produtoSelecionado,
      quantidadeSelecionada,
      catalisador,
      clienteSelecionado
    );
    const handleBeforeUnload = (e) => {
      localStorage.removeItem("items");
      localStorage.removeItem("clienteSelecionado");

      e.preventDefault();
      e.returnValue =
        "Você tem certeza de que deseja sair? Todos os dados do pedido serão perdidos.";
      return e.returnValue;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <div>
      <Header />
      <Conta></Conta>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <h1>Form - Pedido</h1>

        <div className="campos">
          <div className={cx("client_input", { error: errors.client })}>
            <label htmlFor="client">Cliente</label>
            <br />
            <Controller
              name="cliente"
              control={control}
              rules={{ required: "O campo Cliente é obrigatório" }}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  options={opcoesCliente}
                  placeholder="Digite ou selecione o cliente"
                  isClearable
                  isSearchable
                  classNamePrefix="react-select"
                  onChange={(option, actionMeta) => {
                    if (actionMeta.action === "create-option") {
                      const newValue = {
                        label: option.label,
                        value: option.label,
                      };
                      setOpcoesCliente((prevOptions) => [
                        ...prevOptions,
                        newValue,
                      ]);
                      setClienteSelecionado(newValue.value);
                      field.onChange(newValue.value);
                    } else {
                      const value = option ? option.value : "";
                      setClienteSelecionado(value);
                      field.onChange(value);
                    }
                  }}
                  value={
                    opcoesCliente.find((c) => c.value === clienteSelecionado) ||
                    ""
                  }
                />
              )}
            />
            {errors.cliente && (
              <div className="error-message">
                <span>{errors.cliente.message}</span>
              </div>
            )}{" "}
          </div>

          <div className="inputs_qntd_product">
            <label htmlFor="produto">Produto</label>
            <br />
            <Controller
              name="produto"
              control={control}
              rules={{ required: "O campo Produto é obrigatório" }}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  options={produtosFiltrados.map((produto) => ({
                    label: produto["NOME DO PRODUTO"],
                    value: produto["NOME DO PRODUTO"],
                  }))}
                  placeholder="Digite ou selecione o produto"
                  loadOptions={handleInputChange}
                  isClearable
                  isSearchable
                  classNamePrefix="react-select"
                  onChange={(option) => {
                    const value = option ? option.value : "";
                    setProdutoSelecionado(value);
                    field.onChange(value);
                  }}
                  value={
                    field.value
                      ? produtos.find((p) => p["ID DO PRODUTO"] === field.value)
                      : null
                  }
                />
              )}
            />
            {errors.produto && (
              <div className="error-message">
                <span>{errors.produto.message}</span>
              </div>
            )}
            <div className={cx("quantidade", { error: errors.quantidade })}>
              <label htmlFor="produto">Embalagem</label>
              <br />
              <Controller
                name="quantidade"
                control={control}
                rules={{ required: "O campo Embalagem é obrigatório" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={opcoesQuantidade}
                    placeholder="Selecione a Embalagem"
                    isClearable
                    isSearchable
                    classNamePrefix="react-select"
                    onChange={(option) => {
                      const value = option ? option.value : "";
                      setQuantidadeSelecionada(value);
                      field.onChange(value);
                    }}
                    value={
                      opcoesQuantidade.find((q) => q.value === field.value) ||
                      ""
                    }
                  />
                )}
              />
              {errors.quantidade && (
                <div className="error-message">
                  <span>{errors.quantidade.message}</span>
                </div>
              )}
            </div>

            <div className={cx("preco", { error: errors.preco })}>
              <label htmlFor="produto">Preço</label>
              <br />
              <Controller
                name="preco"
                control={control}
                rules={{ required: "O campo Preço é obrigatório" }}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    options={opcoesPreco}
                    placeholder="Selecione ou digite o preço"
                    isClearable
                    isSearchable
                    classNamePrefix="react-select"
                    onChange={(option, actionMeta) => {
                      if (actionMeta.action === "create-option") {
                        const newValue = {
                          label: option.label,
                          value: option.label,
                        };
                        adicionarOpcaoPreco(newValue);
                        setPreco(newValue.value);
                        field.onChange(newValue.value);
                      } else {
                        const value = option ? option.value : "";
                        setPreco(value);
                        field.onChange(value);
                      }
                    }}
                    value={
                      opcoesPreco.find((p) => p.value === field.value) ||
                      field.value // Mantenha o valor criado mesmo que não esteja na lista
                    }
                  />
                )}
              />

              {errors.preco && (
                <div className="error-message">
                  <span>{errors.preco.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className="catalisador_button_radio">
            <label htmlFor="">Substrato Catalisador</label>
            <div className="options">
              <input
                {...register("catalisador", {
                  required: "O campo Catalisador é obrigatório",
                })}
                className={cx("options", { error: errors.catalisador })}
                type="radio"
                value="Vidro - 15% 82F05623"
                name="catalisador"
                checked={catalisador === "Vidro - 15% 82F05623"}
                onChange={handleRadioChange}
              />
              <label htmlFor="">Vidro (15% 82F05623)</label>

              <input
                {...register("catalisador", {
                  required: "O campo Catalisador é obrigatório",
                })}
                className={cx("options", { error: errors.catalisador })}
                type="radio"
                value="Vidro - 12% 82F05623"
                name="catalisador"
                checked={catalisador === "Vidro - 12% 82F05623"}
                onChange={handleRadioChange}
              />
              <label htmlFor="">Vidro (12% 82F05623)</label>

              <input
                {...register("catalisador", {
                  required: "O campo Catalisador é obrigatório",
                })}
                className={cx("options", { error: errors.catalisador })}
                type="radio"
                value="Madeira - 10% 82F00302"
                name="catalisador"
                checked={catalisador === "Madeira - 10% 82F00302"}
                onChange={handleRadioChange}
              />
              <label htmlFor="">Madeira (10% 82F00302)</label>

              <input
                {...register("catalisador", {
                  required: "O campo Catalisador é obrigatório",
                })}
                className={cx("options", { error: errors.catalisador })}
                type="radio"
                value="Madeira - 15% 82F00302"
                name="catalisador"
                checked={catalisador === "Madeira - 15% 82F00302"}
                onChange={handleRadioChange}
              />
              <label htmlFor="">Madeira (15% 82F00302)</label>

              <input
                {...register("catalisador", {
                  required: "O campo Catalisador é obrigatório",
                })}
                className={cx("options", { error: errors.catalisador })}
                type="radio"
                value="Primer - 50% 82F00234"
                name="catalisador"
                checked={catalisador === "Primer - 50% 82F00234"}
                onChange={handleRadioChange}
              />
              <label htmlFor="">Primer (50% 82F00234)</label>

              <input
                {...register("catalisador", {
                  required: "O campo Catalisador é obrigatório",
                })}
                className={cx("options", { error: errors.catalisador })}
                type="radio"
                value="Sem Catalisador"
                name="catalisador"
                checked={catalisador === "Sem Catalisador"}
                onChange={handleRadioChange}
              />
              <label htmlFor="">Sem Catalisador</label>
            </div>
            <ErrorField
              message={errors.catalisador && errors.catalisador.message}
            />
          </div>

          <div className="campo_obs">
            <label htmlFor="">Alguma Observação</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            ></textarea>
          </div>

          <div className="button_confirmar">
            <button type="submit">Ir para o Checkout</button>
          </div>
        </div>
      </form>
      <ConfirmarModal
        show={showConfirmarModal}
        onClose={() => setShowConfirmarModal(false)}
        onAddNewItem={handleAddNewItem}
        onCheckout={handleCheckout}
      />
      <Footer></Footer>
    </div>
  );
}
