// App.js
import React from "react";
import Router from "./components/Router";
import { AppProvider } from "./components/AppContext";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <GoogleOAuthProvider  clientId="375346227359-73lh4jmgk2riii9gi4m9glqrm3jumaur.apps.googleusercontent.com">
      <AppProvider>
        <Router />
      </AppProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
