import React from "react";
import { useSpring, animated } from "react-spring";
import cx from "classnames"; 
import "../assets/styles/style.css";

const ErrorField = ({ message }) => {
  const shake = useSpring({
    transform: message ? "translateX(0)" : "translateX(-10px)",
  });

  return (
    <animated.p
      style={shake}
      className={cx("error", { "error-shake": message })}
    >
      {message}
    </animated.p>
  );
};

export default ErrorField;
