import "../assets/styles/style.css";
import Logo from "../assets/styles/LogoSLdist.png"

export default function Header() {
  return (
    <div className="header">
      <div className="logo"> <img src={Logo}></img> </div>
      <p>SL DISTRIBUIDORA</p>
    </div>
  );
}
