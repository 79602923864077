import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import "../assets/styles/login.css";
import "../assets/styles/style.css";
import GoogleLogo from "../assets/styles/icons8-google-logo-48.png";
import Header from "./Header";

const firebaseConfig = {
  apiKey: "AIzaSyCCGgV6i0SiTP9aaYbhCje2nWgBJplhAXw",
  authDomain: "formsl.firebaseapp.com",
  databaseURL: "https://formsl-default-rtdb.firebaseio.com",
  projectId: "formsl",
  storageBucket: "formsl.appspot.com",
  messagingSenderId: "375346227359",
  appId: "1:375346227359:web:9ab043ce26e69fd9bf9aaa",
  measurementId: "G-MJEPQ0XE58",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  const handleLoginGoogle = async (event) => {
    event.preventDefault();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const authorizedEmails = [
        "laboratorio@santamariasuprimentos.com.br",
        "gabriel.lopes@sltintas.com.br",
        "comercial@sltintas.com.br",
        "junior@sltintas.com.br",
        "rosgelper@gmail.com",
      ];
      if (authorizedEmails.includes(user.email)) {
        // Armazene o token de autenticação no localStorage
        localStorage.setItem("userToken", user.accessToken);
        navigate("/");
      } else {
        alert("Acesso negado. Conta não autorizada.");
      }
    } catch (error) {
      console.error("Falha na autenticação", error.code);
      alert("Falha ao fazer login com o Google.");
    }
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem("userToken");
  };

  return (
    <div className="containerLogin">
      <Header></Header>
      <form onSubmit={handleLoginGoogle}>
        <h1>Login</h1>
        <h4 className="blue_title">
          SL <span> DISTRIBUIDORA</span>
        </h4>

        <div className="inputs_form">
          <div className="google_sign">
            <button type="submit" onClick={handleLoginGoogle}>
              <img src={GoogleLogo} alt="Google logo" /> Continue com Google
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
