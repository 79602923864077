import "../assets/styles/final_page.css";
import "../assets/styles/style.css";
import Header from "./Header";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "./AppContext";

export default function Final() {
  const { setItems } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setItems([]);

    if (location.state?.cliente) {
      localStorage.setItem("cliente", "");
    }
  }, [setItems, location.state]);

  const handleNovoPedido = () => {
    navigate("/");
  };

  return (
    <div className="container">
      <Header />

      <div className="att">
        <div className="imagem">✔</div>
        <h1>Enviado!</h1>
        <p>Seu pedido foi enviado com sucesso</p>
        <button onClick={handleNovoPedido} className="novo-pedido-button">
          Fazer outro pedido
        </button>
      </div>
    </div>
  );
}
