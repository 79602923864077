import "../assets/styles/checkout.css";
import "../assets/styles/style.css";
import Header from "./Header";
import CancelModal from "./CancelModal";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useAppContext } from "./AppContext";
import iconMais from "../assets/styles/mais.png";
import iconMenos from "../assets/styles/menos.png";
import iconDelete from "../assets/styles/excluir.png";
import Footer from "./Footer";
import { getAuth } from "firebase/auth";
import emailjs from "emailjs-com";
import Conta from "./Conta";

export default function Checkout({}) {
  const { items, setItems, atualizarQuantidade } = useAppContext();
  const location = useLocation();
  const { produto, quantidade, catalisador, cliente, observacao } =
    location.state || {};
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [transportadora, setTransportadora] = useState("");
  const [erroFrete, setErroFrete] = useState("");
  const [transportadoraSelecioanda, setTransportadoraSelecionada] =
    useState("");
  const [tipoFrete, setTipoFrete] = useState("");
  const [prazoPagamento, setPrazoPagamento] = useState(["", "", "", ""]);
  const auth = getAuth();
  const user = auth.currentUser;
  const { control } = useForm();
  const prazoRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const navigate = useNavigate();

  const opcoesTransportadora = [
    { label: "ALFA", value: "ALFA" },
    { label: "VIP", value: "VIP" },
    { label: "ROSSATO", value: "ROSSATO" },
    { label: "TAP", value: "TAP" },
    { label: "TRANS APUCARANA", value: "TRANS APUCARANA" },
  ];

  const validarFrete = () => {
    if (!tipoFrete) {
      setErroFrete("Por favor, selecione uma opção de frete.");
      return false;
    }
    setErroFrete("");
    return true;
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const message =
        "Você tem certeza de que deseja sair? Todos os dados do pedido serão perdidos.";
      e.preventDefault();
      e.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      localStorage.removeItem("items");
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (items.length === 0) {
      navigate("/");
    }
    console.log("Itens no início do useEffect:", items);
  }, [items, navigate]);

  const handleConfirmarPedido = () => {
    if (!validarFrete()) {
      return;
    }
    console.log(items);
    console.log("Prazo de Pagamento:", prazoPagamento);
    console.log("Tipo de frete selecionado:", tipoFrete);
    console.log("Transportadora selecionada:", transportadoraSelecioanda);
    // Navegação para a página de agradecimento
    navigate("/agradecimento", { state: { items, cliente: "" } });
    console.log("Dados do pedido ao confirmar:", items);
    const detalhesItens = items
      .map(
        (item) =>
          `Produto: ${item.nome}, Quantidade: ${item.quantidade}, Catalisador: ${item.catalisador}`
      )
      .join("<br>");

    const transportadoraFinal =
      transportadoraSelecioanda || "Sem transportadora";

    const prazoPagamentoFinal =
      prazoPagamento.filter(Boolean).join("/") || "Sem Prazo de Pagamento";

    // Defina os parâmetros do e-mail
    const templateParams = {
      client_name: cliente,
      to_name: "Equipe Comercial",
      from_name: user.displayName || "Nome do Remetente",
      items: items.map((item) => ({
        // Transforma cada item do pedido em um objeto
        nome: item.nome,
        quantidade: item.quantidade,
        embalagem: item.embalagem,
        catalisador: item.catalisador,
        preco: formatarPreco(item.preco),
      })),
      freight_type: tipoFrete,
      carrier: transportadoraFinal,
      payment_term: prazoPagamentoFinal,
      observacao: observacao,
      reply_to: user.email,
    };

    // Envie o e-mail
    emailjs
      .send(
        "service_85owyu5",
        "template_kz5hrq1",
        templateParams,
        "W0tQdxhkCpzImMmtA"
      )
      .then(
        (response) => {
          console.log(
            "E-mail enviado com sucesso!",
            response.status,
            response.text
          );
        },
        (error) => {
          console.log("Falha ao enviar e-mail.", error);
        }
      );
  };

  // Button Qntd
  const incrementQuantidade = (itemId) => {
    const updatedItems = items.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantidade: parseFloat(
              (item.quantidade + item.quantidadeOriginal).toFixed(2)
            ),
          }
        : item
    );
    setItems(updatedItems);
  };

  const decrementQuantidade = (itemId) => {
    const updatedItems = items.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantidade: Math.max(
              0,
              parseFloat((item.quantidade - item.quantidadeOriginal).toFixed(2))
            ),
          }
        : item
    );
    setItems(updatedItems);
  };

  // Button Delete
  const deleteProduto = (itemId) => {
    const updatedItems = items.filter((item) => item.id !== itemId);
    setItems(updatedItems);
    console.log("ID do item a ser deletado:", updatedItems, itemId);
  };

  const handlePrazoChange = (value, index) => {
    const novoPrazo = [...prazoPagamento];
    novoPrazo[index] = value;
    setPrazoPagamento(novoPrazo);
    if (value.length === 2 && index < 3) {
      prazoRefs.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && prazoPagamento[index] === "" && index > 0) {
      prazoRefs.current[index - 1].current.focus();
    }
  };

  const ajustarNumero = (numero) => {
    if (numero.toString().endsWith("99")) {
      return numero + 0.01;
    }
    if (numero.toString().endsWith("226")) {
      return numero - 0.001;
    }
    return numero;
  };

  const formatarPreco = (preco) => {
    preco = preco.trim();

    const valor = parseFloat(preco.replace(/[^\d.,]/g, "").replace(",", "."));

    const ehNumeroPuro = preco.match(/^[\d.,]+$/);

    if (!isNaN(valor) && ehNumeroPuro) {
      return valor.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    } else {
      return preco;
    }
  };
  return (
    <div className="container">
      <Header />
      <Conta></Conta>

      <div className="checkout">
        <div className="header_checkout">
          <div
            className="add"
            onClick={() => {
              navigate("/");
            }}
          >
            +
          </div>
          <h2>Confirmar/Envio</h2>
          <p className="p1Checkout">
            Confirme o pedido do cliente: <b>{cliente}</b>
          </p>
          <hr />
        </div>
        <div className="main">
          <h3>Itens</h3>
          <div className="items">
            {items && items.length > 0 ? (
              items.map((item) => (
                <div key={item.id} className="item">
                  <div className="produto">
                    <p className="nome_produto">Produto: {item.nome}</p>
                    <div className="quantidade">
                      Quantidade:
                      <input
                        type="number"
                        value={ajustarNumero(item.quantidade)}
                        onChange={(e) =>
                          atualizarQuantidade(item.id, e.target.value)
                        }
                        className="quantidade-input"
                        min={0}
                      />
                      LT
                    </div>
                    <p>Embalagem: {item.embalagem}</p>
                    <p>Catalisador: {item.catalisador}</p>
                    <p className="">Preço: {formatarPreco(item.preco)}</p>
                  </div>
                  <div className="buttons_main">
                    <button
                      className="buttonAdd"
                      onClick={() => incrementQuantidade(item.id)}
                    >
                      <img src={iconMais}></img>{" "}
                    </button>
                    <button
                      className="buttonDecre"
                      onClick={() => decrementQuantidade(item.id)}
                    >
                      <img src={iconMenos}></img>
                    </button>
                    <button
                      className="buttonDel"
                      onClick={() => deleteProduto(item.id)}
                    >
                      {" "}
                      <img src={iconDelete}></img>{" "}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>Nenhum item encontrado</p>
            )}
          </div>

          <div className="frete_section">
            <h3>Frete</h3>
            <div className="frete_options">
              <input
                type="radio"
                value="CIF"
                name="frete"
                onChange={(e) => {
                  setTipoFrete(e.target.value);
                  setErroFrete("");
                }}
              />
              <label htmlFor="">Frete CIF (SL Paga)</label>

              <input
                type="radio"
                value="FOB"
                name="frete"
                onChange={(e) => {
                  setTipoFrete(e.target.value);
                  setErroFrete("");
                }}
              />
              <label htmlFor="">Frete FOB (Cliente paga)</label>
            </div>
            {erroFrete && <span className="error-message">{erroFrete}</span>}

            <div className="frete_transportadora">
              <label className="transportadora_label" htmlFor="">
                Transportadora
              </label>
              <Controller
                name="transportadora"
                control={control}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    options={opcoesTransportadora}
                    placeholder="Selecione a Transportadora"
                    isClearable
                    isSearchable
                    onChange={(option) =>
                      setTransportadoraSelecionada(option ? option.value : "")
                    }
                    classNamePrefix="react-select"
                    value={opcoesTransportadora.find(
                      (t) => t.value === field.value
                    )}
                  />
                )}
              />
              <br />
            </div>
            <h3>Prazo de Pagamento</h3>
            <div className="prazo_pag">
              {prazoPagamento.map((valor, index) => (
                <input
                  key={index}
                  ref={prazoRefs.current[index]}
                  type="text"
                  maxLength="2"
                  placeholder="--"
                  value={valor}
                  onChange={(e) => handlePrazoChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </div>
          </div>
          <div className="buttons_confirm">
            <button onClick={handleConfirmarPedido} className="confirme_button">
              Confirmar
            </button>
            <button
              onClick={() => setShowCancelModal(true)}
              className="cancel_button"
            >
              Cancelar
            </button>
            <CancelModal
              show={showCancelModal}
              onClose={() => setShowCancelModal(false)}
              onConfirm={() => {
                setItems([]);
                navigate("/");
                setShowCancelModal(false);
              }}
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
