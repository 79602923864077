// AppContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [items, setItems] = useState(() => {
    // Tenta carregar os itens do localStorage
    const localData = localStorage.getItem("items");
    return localData ? JSON.parse(localData) : [];
  });
  const [ultimoId, setUltimoId] = useState(null);
  const atualizarQuantidade = (itemId, novaQuantidade) => {
    const updatedItems = items.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantidade: parseFloat(novaQuantidade),
            quantidadeOriginal: parseFloat(novaQuantidade),
          }
        : item
    );
    setItems(updatedItems);
  };

  const gerarIdUnico = () => {
    const maxId = items.reduce((max, item) => Math.max(max, item.id), 0);
    return maxId + 1;
  };
  const adicionarItemAoCarrinho = (
    produto,
    quantidade,
    catalisador,
    preco,
    embalagem
  ) => {
    setUltimoId((idAtual) => {
      const novoId = gerarIdUnico();
      const novoItem = {
        id: novoId,
        nome: produto,
        quantidade: parseFloat(quantidade),
        quantidadeOriginal: parseFloat(quantidade),
        catalisador: catalisador,
        embalagem: embalagem,
        preco: preco,
      };
      setItems((prevItems) => [...prevItems, novoItem]);
      console.log("AppContext Embalagem:", embalagem);
      console.log("App Context Preço:", preco)
      return novoId;
    });
  };

  useEffect(() => {
    // Salva os itens no localStorage sempre que 'items' mudar
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  return (
    <AppContext.Provider
      value={{
        items,
        setItems,
        adicionarItemAoCarrinho,
        atualizarQuantidade,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
