import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Form from "./Form";
import Checkout from "./Checkout";
import Final from "./Final";
import Login from "./Login";

// Função para verificar se o usuário está autenticado
const isAuthenticated = () => {
  // Substitua com a lógica de verificação de autenticação do seu app
  return !!localStorage.getItem("userToken");
};

// Componente de rota privada
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/Login" />;
};

export default function Router() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Form />
            </PrivateRoute>
          }
        />
        <Route
          path="/Checkout"
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
        <Route
          path="/agradecimento"
          element={
            <PrivateRoute>
              <Final />
            </PrivateRoute>
          }
        />
        <Route path="/Login" element={<Login />} />
      </Routes>
    </div>
  );
}
